angular.module('informaApp')
    .directive('infSimplemde', ['$parse', function ($parse) {
        return {
            restrict: 'A',
            scope: {
                infSimplemde: '='
            },
            link: function (scope, element, attrs) {
                function render() {
                    return SimpleMDE.prototype.markdown(scope.infSimplemde || "");
                }

                if (attrs['infSimplemde']) {
                    scope.$watch('infSimplemde', function(value) {
                        element.html(render(value));
                    });
                } else {
                    element.html(render(element.text()));
                }
            }
        };
    }
    ]);
